<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortableMessages"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          @click.prevent="initForm"
          data-toggle="modal"
          data-target="#send-sms"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>

    <modal
      title="Lancer une campagne par SMS"
      id="send-sms"
      size="large"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="sending"
      >
        <div class="form-group">
          <label
            for="type"
            class="required"
          >Type de message</label>
          <select
            v-select="{placeholder: 'Selectionnez le type de message'}"
            id="type"
            name="type"
            class="form-control"
            v-model="type"
          >
            <option
              v-for="(t, i) in typeMessages"
              :value="t.type"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div
          class="form-group"
          v-if="isGroupeMessage"
        >
          <label for="groupe">Groupe</label>
          <select
            class="form-control"
            id="groupe"
            name="groupe"
            v-select="{placeholder: 'Selectionnez le groupe'}"
            v-model="groupe"
          >
            <option value="" />
            <option
              :value="g.uid"
              v-for="g in groupes"
              :key="g.uid"
            >
              {{ g.name }} [{{ g.members.length }}]
            </option>
          </select>
        </div>
        <div
          class="form-group"
          v-if="isMessageUnique"
        >
          <label for="proprietaire">Utilisateur</label>
          <select
            class="form-control"
            id="proprietaire"
            name="proprietaire"
            v-select="{placeholder: 'Selectionnez le proprietaire'}"
            v-model="desitinataire"
          >
            <option value="" />
            <option
              :value="p.uid"
              v-for="p in proprietaires"
              :key="p.uid"
            >
              {{ p.fullname }} [{{ p.telephone }}]
            </option>
          </select>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="checkbox-inline">
              <label class="checkbox checkbox-primary">
                <input
                  type="checkbox"
                  name="planify"
                  v-model="planify"
                >
                <span style="margin-left: 20px;" />Planifier
              </label>
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              v-if="planify"
            >
              <label
                for="sendAt"
              >Date de debut</label>
              <datepicker
                id="sendAt"
                name="sendAt"
                input-class="form-control required"
                format="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                :disabled-dates="disabledPlanifyDate"
                v-model="startDate"
              />
            </div>
          </div>
          <div class="col-4">
            <div
              class="form-group"
              v-if="planify"
            >
              <label
                for="endDate"
              >Date de fin</label>
              <datepicker
                id="endDate"
                name="endDate"
                input-class="form-control required"
                format="dd/MM/yyyy"
                placeholder="dd/mm/yyyy"
                :disabled-dates="disabledEndingPlanifyDate"
                v-model="endDate"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="message-input">Message</label>
          <textarea
            rows="5"
            name="message-input"
            id="message-input"
            maxlength="160"
            class="form-control"
            v-model="message"
          />
          <div class="text-right">
            {{ messageLength }}/160
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-right">
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="loading"
            >
              <i class="las la-paper-plane la-2x" />
            </button>
          </div>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import Modal from '../components/modal.vue'
import DataTable from '../components/dataTable/local.vue'
import { BOOLEAN_TYPE, COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE } from '../components/dataTable/dataType'
import navbar from '../components/navbar.vue'
import { ADD_DIRECT_SMS } from '../graphql/user'
import  {LIST_TYPE_SMS, TYPE_SMS_GROUPE, TYPE_SMS_UNIQUE} from '../constants/group'
const Proprietaire = () => import('../components/chat/directSmsProprietaire.vue')
const CreateBy = () => import('../components/sanitaire/createdBy.vue')
const Action = () => import('../components/chat/directSmsAction.vue')
export default {
    components: {navbar, DataTable, Modal, Datepicker},
    data(){
        return {
            navbarItems: [{
                libelle: 'Messagerie'
              },
              {
                libelle: 'Campagne SMS'
            }],
            pageIcon: 'la-sms',
            pageTitle: 'Campagne SMS',
            pageDescription: 'Planifier des campagnes SMS',
            type: null,
            groupe: null,
            planify: false,
            startDate: null,
            endDate: null,
            desitinataire: null,
            message: null,
            has_error: false,
            error_msg: null,
            loading: false
        }
    },
    
    methods: {
        ...mapMutations({
            done: 'DONE'
        }),
        initForm(){
            this.desitinataire = null
            this.message = null
            this.groupe = null
            this.planify = false
            this.startDate = null
            this.endDate = null
            this.type = null
            this.has_error = false
            this.error_msg = null
            this.loading = false
        },
        sending(){
            let data = {
                type: this.type,
                destinataire : this.type === TYPE_SMS_GROUPE? this.groupe : this.desitinataire,
                message: this.message,
                planify: this.planify,
                startDate: this.planify ? this.startDate : new Date(),
                endDate: this.planify? this.endDate: new Date()
            }
            this.loading = true
            this.$apollo.mutate({
                mutation: ADD_DIRECT_SMS,
                variables: {
                    "message": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Direct SMS add successfully.`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
                this.loading = false
            })
        }
    },  
    computed:{
        ...mapGetters({
            sms: 'auth/directSms',
            groupes: 'group/groups',
            proprietaires: 'auth/proprietaires',
            is_super_admin: 'auth/is_super_admin'
        }),
        messageLength(){
            if(this.message === null) return 0
            return this.message.length
        },
        typeMessages(){
          return LIST_TYPE_SMS()
        },
        isGroupeMessage(){return this.type === TYPE_SMS_GROUPE},
        isMessageUnique(){return this.type === TYPE_SMS_UNIQUE},
        sortableMessages(){
            return [...this.sms].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
        },
        disabledPlanifyDate(){
          return {
            to: new Date()
          }
        },
        disabledEndingPlanifyDate(){
          return {
            to: this.startDate
          }
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Destination', name: 'destinataire', type: COMPONENT_TYPE, component: Proprietaire},
                {label: 'Message', name: 'message', type: TEXT_TYPE},
                {label: 'Planifié', name: 'planify', type: BOOLEAN_TYPE},
                {label: 'Date Envoie', name: 'sendAt', type: DATE_TYPE},
                {label: 'Envoyé par', name: 'createBy', type: COMPONENT_TYPE, component: CreateBy},
                {label: 'Date création', name: 'createdAt', type: DATE_TYPE},
                {label: 'Action', name: 'action', type: COMPONENT_TYPE, component: Action},
            ]
        }
    }

}
</script>

<style>

</style>